:root {
    --color-primary: #181a1b;
    --color-secondary: #212628;
    --color-tertiary: #d1cdc7;
    --color-quaternary: #434343;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: var(--color-primary);
    color: var(--color-tertiary);
}

.inspect-footer {
    margin: 10px;
}

.inspect-footer > * button {
    width: 100%;
}

.move-direction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.category-dropdown > * {
    width: 100%;
}

.card {
    background-color: var(--color-secondary);
    border-color: var(--color-quaternary);
    color: var(--color-tertiary);
}

input {
    background-color: var(--color-secondary) !important;
    border-color: var(--color-tertiary) !important;
    color: var(--color-tertiary) !important;
}

.drop-zone {
    border-style: dashed;
    height: 30px;
    border-color: red;
    border-width: 5px;
}

.modal-content {
    background-color: #312d2d !important;
}

.btn-close {
    background-color: aliceblue;
}

textarea.form-control {
    background-color: var(--color-secondary);
    color: var(--color-tertiary);
}

textarea.form-control:focus {
    background-color: var(--color-secondary);
    color: var(--color-tertiary);
}

.single-result {
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 10px;
}

.single-result:hover {
    border: 1px solid red;
}

.selected-result {
    border: 1px solid red;
}